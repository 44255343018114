import React from "react"
import SEO from "../components/seo"
import ShapeGrid from "../components/shapeGrid/shapeGrid"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Shape from "../components/shape/shape"
import UspBand from "../components/uspBand/uspBand"
import ourServices from "../components/ourServices/our-services"
import Triangle from "../components/triangle/triangle"
import Reviews from "../components/reviews/reviews"
const slugify = require("slugify")

const IndexPage = ({ data: { page, shared }, location }) => {
  let random = Math.floor(Math.random() * 8)
  return (
    <>
      <SEO
        titleOverride={page.metaTags && page.metaTags.title ? page.metaTags.title : page.title}
        descriptionOverride={page.metaTags && page.metaTags.description ? page.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={page.metaTags && page.metaTags.image ? page.metaTags.image.url : null}
      />

      <section>
        <div className="container container--3xl">
          <div className="relative flex flex-wrap mb-16 md:mb-24 lg:mb-32">
            <div className="flex-1 mb-12 lg:mb-0">
              <ShapeGrid />
            </div>

            <div className="w-full mb-16 text-center lg:w-7/12 xl:w-5/12 lg:mb-0">
              <div className="flex flex-wrap items-center justify-center h-full">
                <div>
                  <span className="block px-5 text-5xl h1 lg:text-6xl xl:text-7xl 2xl:text-8xl lg:px-24 2xl:px-36 font-display leading-tiny">{ page.heroHeading }</span>
                  
                  <div className="px-5 mb-6 font-sans text-xl font-light leading-tight sm:px-10 md:px-24" dangerouslySetInnerHTML={{ __html: page.heroExcerpt }}></div>
                  
                  <p className="px-5 mb-8 text-2xl leading-tight font-display sm:px-10 md:px-24">Find out how we can help your business <a href="tel:01159598900" className="block transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a></p>

                  <div className="flex flex-wrap justify-center -mx-2">
                    <div className="px-2">
                      <Link to="/work/" className="btn btn--primary md:w-40">Our Work</Link>
                    </div>
                    <div className="px-2">
                      <Link to="/contact/" className="btn btn--outline md:w-40">Contact <span className="hidden sm:inline-block">us</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <ShapeGrid />
            </div>
          </div>
        </div>

        <div className="container flex flex-wrap items-center justify-center py-4 mb-6 md:md:items-stretch lg:mb-24">
          <div className="flex flex-wrap justify-center md:w-full md:justify-between md:-mx-2">
            <div class="w-1/2 md:w-1/6 md:min-w-180 lg:w-1/6 xl:w-1/12 p-1 lg:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-2 border-2 outline-none border-grey">
                <div className="w-full m-auto max-w-170 md:px-2">
                  <div className="m-auto text-center gatsby-image-wrapper ">
                    <Img fluid={shared.agencyAwardLogo.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-1/6 md:min-w-180 lg:w-1/6 xl:w-1/12 p-1 lg:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-2 border-2 outline-none border-grey">
                <div className="w-full m-auto md:px-2">
                  <div className="m-auto text-center gatsby-image-wrapper">
                    <Img fluid={shared.awardLogo.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-1/6 lg:w-1/6 xl:w-2/12 p-1 lg:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-2 border-2 outline-none border-grey">
                <div className="w-full m-auto md:px-2">
                  <div className="m-auto text-center gatsby-image-wrapper">
                    <Img fluid={shared.webExcellenceAwardLogo.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-1/6 lg:w-1/6 xl:w-2/12 p-1 lg:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-2 border-2 outline-none border-grey">
                <div className="w-full m-auto md:px-2">
                  <div className="m-auto text-center gatsby-image-wrapper">
                    <Img fluid={shared.landscaperAward.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-1/6 lg:w-1/6 md:min-w-130 xl:w-1/12 p-1 lg:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-2 border-2 outline-none border-grey">
                <div className="w-full m-auto md:px-2">
                  <div className="m-auto text-center max-w-100 md:max-w-none gatsby-image-wrapper">
                    <Img fluid={shared.baliAward.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-full xl:w-3/12 p-4 flex">
              <div className="flex flex-wrap items-center justify-center w-full p-6 border-2 outline-none border-grey">
                <div className="w-full md:w-auto md:px-2">
                  <div className="w-56 mx-auto text-center">
                    <Reviews hideExtraText />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UspBand
          usps={page.usps}
        />

        <div className="container mb-16 md:mb-32 lg:mb-40">
          <div className="flex flex-wrap xl:items-center">
            <div className="w-full lg:w-1/2 xl:w-5/12">
              <div className="content content--reset" dangerouslySetInnerHTML={{ __html: page.content }}></div>

              <div className="overflow-hidden">
                <div className="flex flex-wrap sm:-mx-2">
                  <div className="w-full mb-4 sm:w-auto sm:px-2 sm:mb-0">
                    <Link to="/services/" className="block w-full btn btn--primary">Digital Services</Link>
                  </div>
                  <div className="w-full sm:w-auto sm:px-2">
                    <Link to="/contact/" className="block w-full btn btn--outline">Contact us</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden ml-auto lg:block lg:w-5/12 xl:w-1/2">
              <Img fluid={page.contentImage.fluid} className="w-full" />
            </div>
          </div>
        </div>
    
      </section>
      
      { page.featuredCaseStudy && (
        <section className="overflow-hidden">
          <div className="container relative">
            <div className="flex flex-wrap items-center pb-6 mb-12 md:-mx-10 lg:-mx-12 xl:-mx-20 md:mb-20 md:pb-24 xl:pb-24">
              <div className="relative w-full mb-12 md:w-1/2 lg:w-5/12 md:px-10 lg:px-12 xl:px-20 md:mb-0">
                <Shape 
                  static={true}
                  index={random}
                  randomColor={true}
                />
                <div className="absolute top-0 z-10 w-full md:right-0 md:w-10/12">
                  <Img fluid={page.featuredCaseStudy.featuredImage.fluid} className="w-full" />
                </div>
              </div>
              <div className="relative z-10 w-full md:w-1/2 lg:w-7/12 md:px-10 lg:px-12 xl:px-20">
                <span className="block text-3xl h1 md:text-4xl lg:text-6xl">{ page.featuredCaseStudy.heroHeading }</span>
                <div className="max-w-2xl mb-6 content content--reset md:mb-10" dangerouslySetInnerHTML={{ __html: page.featuredCaseStudy.heroExcerpt }}></div>

                <div className="flex flex-wrap sm:-mx-2">
                  <div className="w-full mb-4 sm:w-auto sm:px-2 sm:mb-0">
                    <Link to={`/work/${page.featuredCaseStudy.slug}/`} className="block w-full btn btn--primary">Read More</Link>
                  </div>

                  <div className="w-full sm:w-auto sm:px-2">
                    <Link to="/contact/" className="block w-full btn btn--outline">Contact Us</Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="relative overflow-hidden ">

        <div className="container md:mb-10 lg:mb-12 xl:mb-0">
          <div className="flex flex-wrap xl:items-center">
            <div className="w-full">
              <div className="max-w-screen-md content content--reset" dangerouslySetInnerHTML={{ __html: page.contentAddition }}></div>
            </div>
          </div>
        </div>
        
        <div className="container">
          <div className="relative z-10 mb-10 mr-4 bg-transparent md:mr-8 md:mb-10 lg:mb-12 xl:mb-12">
            <div className="flex flex-wrap pt-16 pb-10 pr-12 md:-mx-8 md:pb-0 md:pt-20 lg:pt-24">
              {page.ourServices.map(({ title, model, slug, treeParent, heroExcerpt, icon }, index) => {
                // Generate the top level slug from the models apiKey
                let slugged = slugify(model.apiKey + 's',{
                  lower: true,
                }).replace(/[_]/g, '-');
                let trimmedHeroExcerpt = heroExcerpt.replace(/^(.{120}[^\s]*).*/, "$1...");

                return (
                  <div key={index} className="flex flex-wrap w-full mb-8 md:w-1/2 lg:w-1/4 md:mb-16 md:px-8 lg:mb-20">
                    { icon && (
                      // @TODO convert this to SVG component?
                      <div className="w-full">
                        <svg width="35" height="35" className="block mb-8 lg:mb-10">
                          <image href={icon.url} src={icon.url} width="35" height="35"/>
                        </svg>
                      </div>
                    )}

                    <Link to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`} className="block w-full mb-4 h3">{ title }</Link>

                    { heroExcerpt && (
                      <div className="w-full mb-6 text-lg" dangerouslySetInnerHTML={{ __html: trimmedHeroExcerpt }}></div>
                    )}

                    <Link
                      // if it has a parent, pipe it into the link, if not leave it out 
                      to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                      className="block w-full mt-auto text-lg link text-secondary font-display"
                    >Read more <span className={`ml-2 inline-block text-primary border-gray-lighter border-1`}><Triangle size={`small`} /></span></Link>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="relative z-20 w-full mb-32 md:mb-24 lg:mb-32 xl:mb-16">
            <div className="flex flex-wrap w-full pb-10 mt-10 sm:-mx-2 md:pb-0 md:mt-16">
              <div className="z-10 w-full mb-4 sm:w-auto sm:mb-0 sm:px-2">
                <Link to="/services/" className="block w-full btn btn--primary">Digital Services</Link>
              </div>
              <div className="z-10 w-full sm:w-auto sm:px-2">
                <Link to="/creative-services/" className="block w-full btn btn--primary">Creative Services</Link>
              </div>
              <div className="bottom-0 right-0 hidden w-full -mt-24 sm:block -z-1">
                <div className="mx-auto container--retina -z-1">
                  <div className="w-1/3 ml-auto md:w-4/12 lg:w-3/12 2xl:w-2/12">
                    <Shape 
                      static={true}
                      index={9}
                      color="secondary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-16 md:mb-32 lg:mb-40">
            <div className="flex flex-wrap xl:items-center">
              <div className="w-full lg:w-1/2 xl:w-5/12">
                <div className="content content--reset" dangerouslySetInnerHTML={{ __html: page.contentAdditionTwo }}></div>
              </div>
              <div className="hidden ml-auto lg:block lg:w-5/12 xl:w-1/2">
                <Img fluid={page.contentImageTwo.fluid} className="w-full" />
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    shared: datoCmsShared {
      awardLogo {
        fluid(
          maxWidth: 400
          imgixParams: {h: "814", w: "300"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
      agencyAwardLogo {
        fluid(
          maxWidth: 400
          imgixParams: {h: "814", w: "300"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
      webExcellenceAwardLogo {
        fluid(
          maxWidth: 400
          imgixParams: {h: "514", w: "200"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
      landscaperAward {
        fluid(
          maxWidth: 400
          imgixParams: {h: "514", w: "200"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
      baliAward {
        fluid(
          maxWidth: 400
          imgixParams: {h: "514", w: "200"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
    page: datoCmsHome {
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      title
      heroHeading
      heroExcerpt
      content
      contentAddition
      contentAdditionTwo
      contentImage {
        fluid(
          maxWidth: 700
          imgixParams: {h: "775", w: "700"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      contentImageTwo {
        fluid(
          maxWidth: 700
          imgixParams: {h: "775", w: "700"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      usps {
        heading
        blurb
      }
      slug
      featuredCaseStudy {
        ... on DatoCmsCaseStudy {
          id
          slug
          model {
            id
            apiKey
            name
          }
          heroHeading
          heroExcerpt
          featuredImage {
            fluid(imgixParams: {h: "775", w: "700", fit: "crop", crop: "center" }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsCreativeCaseStudy {
          id
          slug
          model {
            id
            apiKey
            name
          }
          heroHeading
          heroExcerpt
          featuredImage {
            fluid(imgixParams: {h: "775", w: "700", fit: "crop", crop: "center"}) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
      ourServices {
        ... on DatoCmsService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
        ... on DatoCmsCreativeService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
      }
    }
  }
`